import React from 'react';
import { Link } from 'react-router-dom';

const Navigation = () => {
  return (
    <div className="main-nav">
      <ul>
        <Link to='/illustrated-void'><li>illustrated void</li></Link>
        <Link to='/weird-encounters'><li>weird encounters</li></Link>
        <Link to='/animation'><li>her trail</li></Link>
      </ul>
    </div>
  );
};

export default Navigation;
