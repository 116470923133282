import React from 'react';
import './Featured.css';
import Featured from './Featured';
import Accent from './Accent';
import HorizontalPath from './HorizontalPath';


const Home = () => {
  return (
    <div>
      <div className="content">
        <Featured />
      </div>
      <HorizontalPath />
    </div>
  );
}

export default Home;
