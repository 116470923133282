import React from 'react';
import * as THREE from 'three';
import * as dat from 'dat.gui';

class Fundamentals extends React.Component {
  componentDidMount() {
    console.log(this);
    this.main();
  }

  main = () => {

    const fov = 75;
    const aspect = 2;  // the canvas default
    const near = 0.01;
    const far = 1000;
    const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
    camera.position.z = 30;

    const scene = new THREE.Scene();
    scene.background = new THREE.Color( 0x090909 );

    var renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize( window.innerWidth, window.innerHeight  );
    this.mount.appendChild( renderer.domElement );

    const lightColor = 0xE6E6EF;
    const intensity = 1;
    const light = new THREE.DirectionalLight(lightColor, intensity);
    light.position.set(-1, 2, 4);
    scene.add(light);

    light.position.set(-3, 6, 4);
    scene.add(light);

    light.position.set(1, -2, 4);
    scene.add(light);

    light.position.set(1, -2, 1);
    scene.add(light);

    const boxWidth = 2;
    const boxHeight = 2;
    const boxDepth = 2;
    const geometry = new THREE.BoxGeometry(boxWidth, boxHeight, boxDepth);

    function makeInstance(geometry, color, x, y, z) {
      const material = new THREE.MeshPhongMaterial({color});

      const cube = new THREE.Mesh(geometry, material);
      scene.add(cube);

      cube.position.x = x * Math.random();
      cube.position.y = y * Math.random();
      cube.position.z = z * Math.random();

      return cube;
    }

    const cubes = [
      makeInstance(geometry, 0x44aa88,  30, 20, 15),
      makeInstance(geometry, 0x8844aa, 30, 15, 15),
      makeInstance(geometry, 0xaa8844,  30, 15, 15),
      makeInstance(geometry, 0xaa8844,  30, -15, 15),
      makeInstance(geometry, 0x7833aa,  30, -15, 15),
      makeInstance(geometry, 0xaa8844,  -30, 10,15),
      makeInstance(geometry, 0xFFFFFF,  -30, 10,15),
      makeInstance(geometry, 0x7833aa,  -30, -5, 15),
      makeInstance(geometry, 0xaa8844,  -30, -10,15),
      makeInstance(geometry, 0xFFFFFF,  -30, -10,15),
    ];


    function render(time) {
      time *= 0.001;  // convert time to seconds

      cubes.forEach((cube, ndx) => {
        const speed = 1 + ndx * .1;
        const rot = time * speed;
        cube.rotation.x = rot;
        cube.rotation.y = rot;

      });

      renderer.render(scene, camera);
      requestAnimationFrame(render);
    }
  requestAnimationFrame(render);


  }

  render() {
    return <div ref={ref => (this.mount = ref)} style={{backgroundColor: '#090909'}} />;
  }
}

export default Fundamentals;
