import React from 'react';
import { ReactComponent as Artwork } from '../../images/single-star.svg';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';
import styled from "styled-components";


class Featured extends React.Component {

  constructor(props) {
    super(props);
    this.mySvg = React.createRef();    // initialize "this.myInput"
  }

  componentDidMount() {
    console.log(this.mySvg);
  }

  render() {
    let stars = [];
    for (let i = 0; i < 45; i += 1) {
      stars.push(<Artwork key={i}/>);
    }

    return (
      <div className="featured-ctn">
        <Controller>
          <Scene
            duration={1000}
            pin={false}
            indicators={false}
            offset={100}
          >
            <Tween
              from={{ opacity: 0, scale: 0.8 }}
              to={{ scale: 1.3, opacity: '0.9', rotation: 270, y: "random(-60, 60, 5)", x: "random(-120, 120, 5)" }}
              duration={2}
              stagger={{
                // each: 0.4,
                from: "start",
                grid: "auto",
                ease: "power2.inOut",
                amount: 1.2
              }}
              ease="elastic.out(0.2, 0.1)"
            >
              {stars.map(star =>  star)}
            </Tween>
          </Scene>
        </Controller>
      </div>
    );
  }
}


export default Featured;
