import React from 'react';
import * as THREE from "three";
import * as dat from 'dat.gui';
// import { SVGLoader } from "three/examples/jsm/loaders/SVGLoader";
import '../animation.css';
import texture1 from './stripe.jpg';
import brick from './brick.jpg';

class Test extends React.Component {
  componentDidMount() {
    const ratio = window.innerWidth / window.innerHeight;
    var scene = new THREE.Scene();
    scene.background = new THREE.Color( 0x090909 );

    var camera = new THREE.PerspectiveCamera(
      75,
      ratio,
      0.1,
      1000
    );
    camera.position.y = 3;
    var renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setSize( window.innerWidth, window.innerHeight  );
    this.mount.appendChild(renderer.domElement);

    var geometry1 = new THREE.BoxGeometry(20, 30, 70);
    var geometry2 = new THREE.SphereGeometry( 15, 19, 15 );
    var circle;
    var geometry3 = new THREE.ConeGeometry( 5, 20, 32 );
    var points = [];
    for ( var i = 0; i < 40; i ++ ) {
    	points.push( new THREE.Vector2( Math.sin( i * 0.2 ) * 10 + 5, ( i - 5 ) * 2 ) );
    }
    var geometry4 = new THREE.LatheGeometry( points );
    // var material = new THREE.MeshLambertMaterial({color: 0xCCCCFF, wireframe: false});
    var textureLoader = new THREE.TextureLoader();
    // we've gotta set this to use cross-origin images
    textureLoader.crossOrigin = true;
    // load in the image
    textureLoader.load(texture1, function(texture) {
      texture.wrapS = texture.wrapT =   THREE.RepeatWrapping;
        texture.repeat.set( 1, 5 );
        var material = new THREE.MeshLambertMaterial( {map: texture} );
        var circle = new THREE.Mesh(geometry2, material);

        circle.position.y = 40;
        circle.position.x = 40;
        circle.position.z = 30;
        scene.add( circle );

        var render = () => {
          renderer.render(scene, camera);
          const time = 0.01;

          // Rotate out group
          // cube.rotation.y += 0.001;
          circle.rotation.y += time * 3;
          circle.rotation.x += time;
          // cone.rotation.y -= 0.001;
          // lathe.rotation.y += 0.01;
          requestAnimationFrame(render);
        };

      render();
    });
    var color = new THREE.Color( "#7833aa" );

    textureLoader.load(brick, texture => {
      texture.wrapS = texture.wrapT =   THREE.RepeatWrapping;
      texture.repeat.set( 1, 1 );
      // var material = new THREE.MeshLambertMaterial( {map: texture} );
      var material = new THREE.MeshPhongMaterial( {color: color.getHex(), bumpMap: texture} );
      var cube = new THREE.Mesh(geometry1, material);
      cube.position.x = -55;
      cube.position.z = -10;
      scene.add(cube);

      var render = () => {
        renderer.render(scene, camera);

        // Rotate out group
        // cube.rotation.y += 0.001;
        // circle.rotation.y += 0.01;
        // cone.rotation.y -= 0.001;
        // lathe.rotation.y += 0.01;
        requestAnimationFrame(render);
      };

    render();
    });

    // var cube = new THREE.Mesh(geometry1, material);
    // // var circle = new THREE.Mesh(geometry2, material);
    // var cone = new THREE.Mesh(geometry3, material);
    // var lathe = new THREE.Mesh(geometry4, material);
    // cube.position.x = -75;
    // cone.position.x = 75;
    // // circle.position.y = 40;
    // // circle.position.x = 40;
    // // circle.position.z = 30;
    // lathe.position.z = 25;
    // lathe.position.y = -25;
    // scene.add(cube);
    // // scene.add(circle);
    // scene.add(cone);
    // scene.add(lathe);


    camera.position.z = 113;

    // var light = new THREE.PointLight( 0xFFFFFF , 1);
    // light.position.set( -10, 10, 80 );
    // scene.add( light );
    //
    // var light = new THREE.PointLight( 0xFFFFFF , 1);
    // light.position.set( 10, -10, -80 );
    // scene.add( light );
    // so many lights
    var light = new THREE.DirectionalLight( 0xffffff, 1 );
    light.position.set( 0, 1, 0 );
    scene.add( light );

    var light = new THREE.DirectionalLight( 0xffffff, 0.5 );
    light.position.set( 0, -1, 0 );
    scene.add( light );

    var light = new THREE.DirectionalLight( 0xffffff, 1 );
    light.position.set( 1, 0, 0 );
    scene.add( light );

    var light = new THREE.DirectionalLight( 0xffffff, 0.5 );
    light.position.set( 0, 0, 1 );
    scene.add( light );

    var light = new THREE.DirectionalLight( 0xffffff, 1 );
    light.position.set( 0, 0, -1 );
    scene.add( light );

    var light = new THREE.DirectionalLight( 0xffffff, 0.5 );
    light.position.set( -1, 0, 0 );
    scene.add( light );



    var grid = new THREE.GridHelper( 1000, 100, 0xffffff, 0xffffff );
    grid.material.opacity = 0.1;
    grid.material.transparent = true;
    scene.add( grid );

    // var render = () => {
    //   renderer.render(scene, camera);
    //
    //   // Rotate out group
    //   // cube.rotation.y += 0.001;
    //   // this.circle.rotation.x += 0.01;
    //   // cone.rotation.y -= 0.001;
    //   // lathe.rotation.y += 0.01;
    //   requestAnimationFrame(render);
    // };
    // render();

    // // dat gui
    // var gui = new dat.GUI();
    // var cameraGui = gui.addFolder("camera position");
    // cameraGui.add(camera.position, 'x');
    // cameraGui.add(camera.position, 'y');
    // cameraGui.add(camera.position, 'z');
    // cameraGui.open();
    //
    // var cameraGui = gui.addFolder("camera projection");
    // cameraGui.add(camera, "fov");
    // cameraGui.open();
    //
    // var lightGui = gui.addFolder("light position");
    // lightGui.add(light.position, 'x');
    // lightGui.add(light.position, 'y');
    // lightGui.add(light.position, 'z');
    // lightGui.open();
    //
    // var cubeGui = gui.addFolder("cube position");
    // cubeGui.add(cube.position, 'x');
    // cubeGui.add(cube.position, 'y');
    // cubeGui.add(cube.position, 'z');
    // cubeGui.open();

  }

  render() {
    return <div ref={ref => (this.mount = ref)} style={{backgroundColor: '#090909'}} />;
  };
}

export default Test;
