import React from 'react';
import Video from "../../images/video.mp4"

const Artworks = () => {
  return (
    <div className="content">
      <h1 style={{color: 'white'}}>Artworks</h1>
      <video loop autoPlay src={Video} type="video/mp4" />
    </div>
  );
};

export default Artworks;
