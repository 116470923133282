import React from 'react';

import Animation from "./threejs/Animation";
import Test from './threejs/Test';
import Fundamentals from './threejs/Fundamentals';
import './animation.css';


export default class ThreeD extends React.Component {

    componentDidMount() {
        // threeEntryPoint(this.threeRootElement);
    }

    render () {
        return (
            <div ref={element => this.threeRootElement = element} >
              <Fundamentals />
              <Test />
              <Animation />
            </div>
        );
    }
}
