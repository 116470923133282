import React from 'react';

const Posts = () => {
  return (
    <div className="content">
      <h1 style={{color: 'white'}}>Posts</h1>
    </div>
  );
};

export default Posts;
