import React from 'react';
import * as THREE from "three";
import { SVGLoader } from "three/examples/jsm/loaders/SVGLoader";
import '../animation.css';
import icon from './rose-2.svg';

class Animation extends React.Component {

  componentDidMount() {
    var scene = new THREE.Scene();
    scene.background = new THREE.Color( 0x090909 );
    // scene.fog = new THREE.Fog( 0xe0e0e0, 20, 10 );
    var spotLight = new THREE.SpotLight( 0xffffff, 1 );
		spotLight.position.set( 15, 40, 35 );
		spotLight.angle = Math.PI / 4;
		spotLight.penumbra = 0.05;
		spotLight.decay = 2;
		spotLight.distance = 200;

		spotLight.castShadow = true;
		spotLight.shadow.mapSize.width = 1024;
		spotLight.shadow.mapSize.height = 1024;
		spotLight.shadow.camera.near = 10;
		spotLight.shadow.camera.far = 200;
		scene.add( spotLight );

    var grid = new THREE.GridHelper( 1000, 100, 0xffffff, 0xffffff );
		grid.material.opacity = 0.1;
		grid.material.transparent = true;
		scene.add( grid );

    const ratio = window.innerWidth / window.innerHeight;
    // const camera = new THREE.PerspectiveCamera(100, ratio, 0.01, 1000);
    // camera.position.z = 200;
    const camera = new THREE.PerspectiveCamera( 75, ratio, 0.01, 1000 );
		camera.position.set( - 45, 50, 350 );
		camera.lookAt( new THREE.Vector3( 0,20,0 ) );
    // camera.position.z = 100;

    var renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize( window.innerWidth, window.innerHeight  );
    this.mount.appendChild( renderer.domElement );

    const loader = new SVGLoader();
    loader.load(
  	// resource URL
  	icon,
  	// called when the resource is loaded
  	function ( data ) {

  		var paths = data.paths;
  		var group = new THREE.Group();
      group.scale.y *= -1;
      console.log(paths);
      // const material = new THREE.MeshNormalMaterial();

      for ( var i = 0; i < paths.length; i ++ ) {

  			var path = paths[ i ];
  			var shapes = path.toShapes( true );

        var fillColor = path.userData.style.fill;

        var material = new THREE.MeshBasicMaterial( {
								color: new THREE.Color().setStyle( fillColor ),
								opacity: path.userData.style.fillOpacity,
								transparent: path.userData.style.fillOpacity < 1,
								side: THREE.DoubleSide,
								depthWrite: false,
								wireframe: true
							} );

  			for ( var j = 0; j < shapes.length; j ++ ) {
  				var shape = shapes[ j ];
          const geometry = new THREE.ExtrudeGeometry(shape, {
            depth: 1,
            bevelEnabled: false
          });
          const mesh = new THREE.Mesh(geometry, material);
          group.add(mesh);

  			}
		}

    // Get group's size
    const box = new THREE.Box3().setFromObject(group);
    const size = new THREE.Vector3();
    box.getSize(size);

    const yOffset = size.y / -2;
    const xOffset = size.x / -2;

    group.children.forEach(item => {
      item.position.x = xOffset;
      item.position.y = yOffset;
    });

		scene.add( group );

    function animate() {
      renderer.render(scene, camera);

      // Rotate out group
      group.rotation.y += 0.01;

      requestAnimationFrame(animate);
    }

    animate();

    });



  }

  render() {
    return (
      <div ref={ref => (this.mount = ref)} style={{backgroundColor: '#090909'}} />
    )
  }
}

export default Animation;
