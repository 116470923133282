import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Header from './header/Header';
import Home from './home/Home';
import Posts from './posts/Posts';
import Artworks from './artworks/Artworks';
import ThreeD from './animation/ThreeD';

import './global.css';

const App = () => {
  return (
    <div className="container">
      <Router>
        <Header />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/illustrated-void" exact component={Artworks} />
          <Route path="/weird-encounters" exact component={Posts} />
          <Route path="/animation" exact component={ThreeD} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
