import React from 'react';
import './Accent.css';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';

const HorizontalPath = () => {
  return (
    <div className="hor-div" style={{height: 'auto', textAlign: 'center'}}>
      <Controller>
        <Scene
          duration={5000}
          pin={true}
          indicators={false}
          offset={0}
        >

          <Timeline
            target={
              <div style={{ width: '300px', height: '300px', border: '5px solid #fff' }}></div>
            }
          >
            <Tween from={{ x: "1800px", opacity: 0.4, rotation: '360' }} to={{x: "700", opacity: 0.6, rotation: '0' }} duration={1} />
            <Tween to={{x: "400", opacity: 1, scale: 0.6, y: "-200"  }} duration={0.5} />
            <Tween to={{ x: '-500px', scale: 1.3, rotation: 270 }} duration={1} />

          </Timeline>
        </Scene>
      </Controller>
    </div>
  );
};

export default HorizontalPath;
